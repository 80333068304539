<template>

    <div class="wrap p-login-intro">
        <!-- header -->
        <layout-header title="" z-index="-1"></layout-header>

        <!-- container -->
        <div class="container">
            <div class="content">
                <div class="guide-text">
                    <img src="/assets/images/member/img_login_intro.png" alt="">
                    <strong>간편한 회원가입을 통하여<br>편리한 서비스를 이용해 보세요.</strong>
                </div>
				<div class="button-area">
                    <template v-if="ENV === 'IOS' || ENV === 'WEB'">
                        <a class="btn-kakao" @click="kakaoLoginRest" style="font-size: 13px;"><i></i><span>카카오로 시작하기</span></a>
                    </template>
                    <template v-else>
                        <a class="btn-kakao" @click="kakaoLogin" style="font-size: 13px"><i></i><span>카카오로 시작하기</span></a>
                    </template>

                    <template v-if="ENV === 'IOS' || ENV === 'WEB'">
                        <a class="btn-naver" @click="naverLoginRest" style="font-size: 13px"><i></i><span>네이버로 시작하기</span></a>
                    </template>
                    <template v-else>
                        <a class="btn-naver" @click="naverLogin" style="font-size: 13px"><i></i><span>네이버로 시작하기</span></a>
                    </template>
                    <a href="javascript:" id="naver_id_login"></a>

                    <a class="btn-email" href="/login"><i></i><span style="font-size: 13px">이메일로 시작하기</span></a>

                    <a class="btn-apple" @click="appleLogin"><i></i><span style="font-size: 13px">Apple로 로그인</span></a>
                    <p id="appleid-signin" data-border="true" data-type="sign in"></p>
					<input type="text" id="deviceToken" v-show="LOCAL" placeholder="장치 토큰" readonly="readonly">
				</div>
            </div>
        </div>
    </div>

</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader"
import $ from 'jquery'

export default {
    name: "snsJoin",
    components: {LayoutHeader},
    data() {

        let ENV = this.commonUtils.getEnv()
        const LOCAL = this.commonUtils.isLocal()

        return {

            BASE_URL: this.APIs.BASE_URL,
			// BASE_URL: "http://192.168.0.219:8081",

            KAKAO_LOGIN_KEY: this.APIs.KAKAO_LOGIN_KEY,
            KAKAO_LOGIN_KEY_REST: this.APIs.KAKAO_LOGIN_KEY_REST,
            KAKAO_LOGIN_RETURN_REST: this.APIs.KAKAO_LOGIN_RETURN_REST,

            NAVER_LOGIN_KEY: this.APIs.NAVER_LOGIN_KEY,
            NAVER_LOGIN_SECRET: this.APIs.NAVER_LOGIN_SECRET,
            NAVER_LOGIN_RETURN: this.APIs.NAVER_LOGIN_RETURN,
            NAVER_LOGIN_RETURN_REST: this.APIs.NAVER_LOGIN_RETURN_REST,
            NAVER_LOGIN_DOMAIN: this.APIs.NAVER_LOGIN_DOMAIN,

            APPLE_CLIENT_ID: this.APIs.APPLE_CLIENT_ID,
            APPLE_LOGIN_RETURN: this.APIs.APPLE_LOGIN_RETURN,

            ENV,

            deviceToken: "",
            naverCallVal: "",
            naverCallRestVal: "",

            LOCAL,

            NAVER_READY: false,
            KAKAO_READY: false,
            APPLE_READY: false
        }

    },
    created() {

		if ((location.protocol === "http:" || location.hostname !== 'www.masha-bear.co.kr') && location.href.indexOf("localhost") === -1) {
			location.href = location.href.replace(location.hostname, 'www.masha-bear.co.kr').replace(location.protocol, 'https:')
		}
		this.doLogoutWithOutReload()
        this.sessionUtils.isLogin() ? this.commonUtils.localLog("현재 로그인이 되어 있습니다.") : {}
    },
    mounted() {

		if (this.appDevice() === 'WEB' && this.commonUtils.checkOS() === 'android') {
			this.APPLE_LOGIN_RETURN = this.APPLE_LOGIN_RETURN.replaceAll(this.webUtils.getSubdomain() + '.', '')
		}

        this.commonUtils.deviceToken('#deviceToken')

        this.$loadScript("https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js")
            .then(() => {
                this.naverInit()
                this.NAVER_READY = (typeof naver_id_login === 'function')
                if (this.NAVER_READY) {
                    this.commonUtils.localLog("Naver login api in loaded")
                } else {
                    this.commonUtils.localErr("Naver login api in not loaded")
                }
            })
            .catch(() => {
                this.commonUtils.localErr("Naver login api in not loaded")
            })

        this.$loadScript("https://developers.kakao.com/sdk/js/kakao.js")
            .then(() => {
                this.kakaoInit()
                this.KAKAO_READY = (typeof Kakao == 'object')
                if (this.KAKAO_READY) {
                    this.commonUtils.localLog("Kakao login api in loaded")
                } else {
                    this.commonUtils.localErr("Kakao login api in not loaded")
                }
            })
            .catch(() => {
                this.commonUtils.localErr("Kakao login api in not loaded")
            })

        this.$loadScript("https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js")
            .then(() => {
                this.appleInit()
                this.APPLE_READY = (typeof AppleID === 'object')
                if (this.APPLE_READY) {
                    this.commonUtils.localLog("Apple login api in loaded")
                } else {
                    this.commonUtils.localErr("Apple login api in not loaded")
                }
            })
            .catch((e) => {
                this.commonUtils.localErr("Apple login api in not loaded")
            })
    },
    methods: {

        goReferrer() {
            if (!this.stringUtils.isEmpty(this.$route.query.referrer)) {
                location.href = this.$route.query.referrer
            } else {
                location.href = "/"
            }
        },

        init() {

            this.naverInit()
            this.kakaoInit()
            this.appleInit()

        },

        naverInit() {

            const self = this

            this.naverCallRestVal = new window.naver_id_login(self.NAVER_LOGIN_KEY, self.NAVER_LOGIN_RETURN_REST)
            this.naverCallRestVal.setButton("white", 3, 40)
            this.naverCallRestVal.setDomain(self.NAVER_LOGIN_DOMAIN)
            this.naverCallRestVal.setState(this.naverCallRestVal.getUniqState())
            this.naverCallRestVal.setPopup()
            this.naverCallRestVal.init_naver_id_login()

            this.naverCallVal = new window.naver_id_login(self.NAVER_LOGIN_KEY, self.NAVER_LOGIN_RETURN)
            this.naverCallVal.setButton("white", 3, 40)
            this.naverCallVal.setDomain(self.NAVER_LOGIN_DOMAIN)
            this.naverCallVal.setState(this.naverCallVal.getUniqState())
            this.naverCallVal.setPopup()
            this.naverCallVal.init_naver_id_login()

            $('#naver_id_login').css('display', 'none')

        },

        naverLogin() {

            location.href = $('#naver_id_login_anchor').attr('href')

            // window.open($('#naver_id_login_anchor').attr('href'), 'naverloginpop', 'titlebar=1, resizable=1, scrollbars=yes, width=600, height=550')

        },

        naverLoginRest() {

            const self = this
            location.href = 'https://nid.naver.com/oauth2.0/authorize?'
                + 'client_id=' + self.naverCallRestVal.client_id + '&'
                + 'redirect_uri=' + self.naverCallRestVal.redirect_uri + '&'
                + 'response_type=' + 'code' + '&'
                + 'state=' + self.naverCallRestVal.state

        },

        kakaoInit() {

            const self = this

            if (!window.Kakao.isInitialized()) {
                window.Kakao.init(self.KAKAO_LOGIN_KEY)
            }

            const kakaoInitResult = window.Kakao.isInitialized()

            if (!kakaoInitResult) {
                console.log(kakaoInitResult.name, kakaoInitResult.message)
            }

        },

        kakaoLogin() {

            const self = this

            if (!window.Kakao.isInitialized()) {
                this.kakaoInit()
            }

            window.Kakao.Auth.login({
                success: function () {

                    window.Kakao.API.request({
                        url: '/v2/user/me',
                        success: function (res) {

                            let snsId
                            let email

                            try {
                                snsId = res.id
                                email = res.kakao_account.email
                            } catch (e) {
                                self.commonUtils.localWarn(e)
                            }

                            self.commonUtils.localWarn("snsId : " + snsId, "email : " + email)

                            self.axiosCaller.get(self, self.APIs.SESSION + '/doSnsLogin', {
                                snsService: 'kakao',
                                snsId: snsId,
                                email: email,
                                deviceToken: (self.webUtils.isDom('#deviceToken')) ? document.querySelector('#deviceToken').value : "",
                            }, async (res) => {

                                const result = res.data

                                if (result.status == 'duplicate') {

                                    self.swalUtils.gritter("이미 가입된 계정입니다.", result.service + "으로 로그인 해 주세요.", "warning", 3000)
                                        .then(t => {
                                            self.$router.push({
                                                name: "snsJoin"
                                            })
                                        })

                                } else if (!result.coAccountVO.isUse) {
									self.swalUtils.fire("탈퇴된 계정입니다.", "관리자에게 문의바랍니다.", "info", null, null).then(
										r => {
											self.$router.push({
												name: "snsJoin"
											})
										}
									)
								} else {

                                    if (await self.setMainSessionProc(result.coAccountVO)) {
                                        self.setMainSessionKeep(self.isKeepSession)

                                        await self.$router.push({
                                            name: "profile"
                                        })

                                    }

                                }

                            }, (e) => {
                                self.commonUtils.localErr(e)
                            })

                        },
                        fail: function (e) {
                            self.commonUtils.localErr(e.error, e.error_description)
                        }
                    })

                },
                fail: function (e) {
                },
            })

        },

        kakaoLoginRest() {

            const self = this
            const url = 'https://kauth.kakao.com/oauth/authorize?'
                + 'client_id=' + self.KAKAO_LOGIN_KEY_REST + '&'
                + 'redirect_uri=' + self.KAKAO_LOGIN_RETURN_REST + '&'
                + 'response_type=' + 'code'

            location.href = url

        },

        appleInit() {

            const self = this

            if (self.APPLE_CLIENT_ID === '' || self.APPLE_LOGIN_RETURN === '') {
                return
            }

            window.AppleID.auth.init({
                clientId: self.APPLE_CLIENT_ID,
                scope: "name email",
                redirectURI: self.APPLE_LOGIN_RETURN,
                state: "origin:web",
                usePopup: true,
            })

            document.addEventListener('AppleIDSignInOnSuccess', (data) => {
                this.appleCallback(data)
            })

            document.addEventListener('AppleIDSignInOnFailure', (e) => {
            })

            $('#appleid-signin').css('display', 'none')

        },

        appleLogin() {

            document.querySelector('#appleid-signin').click()

        },

        appleCallback(_data) {

            const self = this

            // eslint-disable-next-line no-undef
            const res = JSON.parse(atob((_data.detail.authorization.id_token).split('.')[1]))
            const snsId = res.sub
            // eslint-disable-next-line no-unused-vars
            let name
            let email

            // eslint-disable-next-line no-undef
            if (_data.detail.user != null) {
                // eslint-disable-next-line no-undef,no-unused-vars
                name = _data.detail.user.name.lastName + _data.detail.user.name.firstName
                // eslint-disable-next-line no-undef
                email = _data.detail.user.email
            }

            if (res.email_verified === "true") {
                email = res.email
            }

            self.commonUtils.localWarn("snsId : " + snsId, "email : " + email)

            self.axiosCaller.get(self, self.APIs.SESSION + '/doSnsLogin', {
                snsService: "apple",
                snsId: snsId,
                email: email,
                deviceToken: (self.webUtils.isDom('#deviceToken')) ? document.querySelector('#deviceToken').value : "",
            }, async (res) => {

                const result = res.data

                if (result.status == 'duplicate') {

                    self.swalUtils.gritter("이미 가입된 계정입니다.", result.service + "으로 로그인 해 주세요.", "warning", 3000)
                        .then(t => {
                            self.$router.push({
                                name: "snsJoin"
                            })
                        })

                } else if (!result.coAccountVO.isUse) {
					self.swalUtils.fire("탈퇴된 계정입니다.", "관리자에게 문의바랍니다.", "info", null, null).then(
						r => {
							self.$router.push({
								name: "snsJoin"
							})
						}
					)
				} else {

                    if (await self.setMainSessionProc(result.coAccountVO)) {
                        self.setMainSessionKeep(self.isKeepSession)

                        await self.$router.push({
                            name: "profile"
                        })

                    }

                }

            }, (e) => {
                self.commonUtils.localErr(e)
            })

        },
    }

}
</script>

<style scoped>
</style>